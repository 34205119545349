import { Mixins, Component } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import { Settings } from "@planetadeleste/vue-mc-gw";
import { ConfigModule } from "@/store/config";

const TypedModelMixin = ModelMixin as unknown as VueClass<ModelMixin<Settings>>;

@Component
export default class SettingsMixin extends Mixins(TypedModelMixin) {
  obModelClass = Settings;
  sRouteName = "settings";

  created() {
    this.onCreated();
  }

  async index() {
    await this.obModel.fetch();
  }

  saved() {
    ConfigModule.load();
  }
}
